import { Link } from 'react-router-dom';

export interface UserCardProperties {
  userId: string;
  name: string;
  firstName: string;
  selected: boolean;
  path: string;
}

export const UserCard = (props: UserCardProperties) => {
  return (
    <Link
      to={`/admin/users/${props.userId}/${props.path}`}
      className={`outline-focus flex h-16 w-48 flex-col items-center justify-center rounded-lg outline-offset-4 ${props.selected ? 'bg-accent text-white' : 'bg-primary'}`}
    >
      <span className="font-medium">{props.name}</span>
      <span className="text-sm">{props.firstName}</span>
    </Link>
  );
};
