import { RuleBase, RuleSpec, RulesEngineResult } from '../rule-base';

export class RuleWeekendNeeds extends RuleBase {
  test(): RulesEngineResult {
    return { status: 'ok' };
  }
  specs(): RuleSpec[] {
    return [
      {
        id: 'weekend-needs-full',
        description:
          'Über {weeks} Wochen müssen an {count} Wochenenden Sa und So frei sein',
        defaults: [
          { key: 'weeks', type: 'number', value: '12' },
          { key: 'count', type: 'number', value: '3' }
        ]
      },
      {
        id: 'weekend-needs-part',
        description:
          'Über {weeks} Wochen müssen an {count} Wochenenden auch entweder Sa oder So frei sein',
        defaults: [
          { key: 'weeks', type: 'number', value: '12' },
          { key: 'count', type: 'number', value: '3' }
        ]
      }
    ];
  }
}
