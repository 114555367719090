import { useEffect, useId, useRef, useState } from 'react';
import { applyBlur } from './input-blurs';

export interface InlineInputProps {
  value: string;
  mask?: 'time' | 'date' | 'number';
  onChange?: (value: string) => void;
}

export const InlineInput = (props: InlineInputProps) => {
  const id = useId();
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setEdit(false);
    const value = applyBlur(
      e.target.value,
      'text',
      undefined,
      undefined,
      props.mask
    );
    setValue(value);
    props.onChange?.(value);
  };

  const handleOnFocus = (e: React.FocusEvent) => {
    setEdit(true);
    setTimeout(() => {
      ref.current?.focus();
    });
  };

  return (
    <div
      tabIndex={edit ? -1 : 0}
      className={`grid outline-none ${edit ? 'grid-rows-[0_auto]' : 'grid-rows-[auto_0]'}`}
      onFocus={handleOnFocus.bind(this)}
    >
      <label
        htmlFor={id}
        className="block min-h-6 overflow-clip px-1"
        onClick={setEdit.bind(this, true)}
      >
        {value}
      </label>
      <input
        ref={ref}
        id={id}
        className="outline-focus w-full rounded px-1 outline-offset-2"
        style={{ display: edit ? 'block' : 'none' }}
        value={value}
        autoComplete="off"
        onBlur={handleBlur.bind(this)}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};
