import {
  RulesEngineResult,
  WorkHistory,
  RuleBase,
  Assignment,
  InMins24Hrs,
  RuleSpec,
  BlockStatus,
  ShiftScope
} from '../rule-base';
import { containsNightShift, totalMins } from './_definitions';

/**
 * After night shift, the rest period must be at least 48 hours
 * when the night shift ends by 9:00 AM
 */
export class RulesRestAfterNightShift extends RuleBase {
  beforeDescription =
    'Endet ein Nachtdienst vor {end} Uhr, muss die Ruhezeit mindestens {length} Tage betragen';
  beforeThresholds = { end: '09:00', endMins: 9 * 60, length: 1 };

  afterDescription =
    'Endet ein Nachtdienst nach {end} Uhr, muss die Ruhezeit mindestens {length} Tage betragen';
  afterThresholds = { end: '09:00', endMins: 9 * 60, length: 2 };

  specs(): RuleSpec[] {
    return [
      {
        id: 'follow-night-shift-before',
        description: this.beforeDescription,
        defaults: [
          this.specVal('length', 'number', this.beforeThresholds.length),
          this.specVal('end', 'time', this.beforeThresholds.end)
        ]
      },
      {
        id: 'follow-night-shift-after',
        description: this.afterDescription,
        defaults: [
          this.specVal('length', 'number', this.afterThresholds.length),
          this.specVal('end', 'time', this.afterThresholds.end)
        ]
      }
    ];
  }

  test(from: Date, until: Date, history?: WorkHistory): RulesEngineResult {
    if (!history) {
      return { status: 'ok' };
    }

    const days = [...history.previous, ...history.current, ...history.next];
    if (days.length === 0) {
      return { status: 'ok' };
    }

    return { status: 'ok' };
  }

  check(
    test: ShiftScope,
    _days: Date[],
    plan: Assignment[][],
    blocked: BlockStatus[]
  ): void {
    //look over the assigned shifts, and anything that has something running over midnight should place a block on the next day
    for (const [day, shifts] of plan.entries()) {
      for (const shift of shifts) {
        if (containsNightShift(shift)) {
          const end = shift.totalMins + shift.duration - InMins24Hrs;
          const days = this.getBreakDays(end);
          const note = this.getNote(end, days);
          for (let i = 1; i <= days; i++) {
            this.setBlocked(blocked, day + i, note);
          }
        }
      }
    }

    const testIncludesNightShift = containsNightShift(test);
    if (testIncludesNightShift) {
      const end = totalMins(test) - InMins24Hrs;
      const days = this.getBreakDays(end);
      const note = this.getNote(end, days);
      for (let day = -days; day < plan.length - days; day++) {
        const dayOfInterest = plan[day + days];
        if (dayOfInterest.filter((x) => !x.readonly).length > 0) {
          for (let i = 0; i < days; i++) {
            this.setBlocked(blocked, day + i, note);
          }
        }
      }
    }
  }

  private getBreakDays(end: number): number {
    if (end < this.beforeThresholds.endMins) {
      return this.beforeThresholds.length;
    } else {
      return this.afterThresholds.length;
    }
  }

  private getNote(end: number, days: number): string {
    if (end < this.beforeThresholds.endMins) {
      return this.beforeDescription
        .replace('{length}', `${days}`)
        .replace('{end}', this.beforeThresholds.end);
    } else {
      return this.afterDescription
        .replace('{length}', `${days}`)
        .replace('{end}', this.beforeThresholds.end);
    }
  }
}
