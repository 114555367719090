import { RuleBase, RuleSpec, RulesEngineResult } from '../rule-base';

export class RuleOnCallRequired extends RuleBase {
  test(): RulesEngineResult {
    return { status: 'ok' };
  }
  specs(): RuleSpec[] {
    return [
      {
        id: 'on-call-required',
        description:
          'Dienste, die zwischen {from} und {to} Uhr stattfinden, werden als Bereitschaftsdienst eingestuft',
        defaults: [
          { key: 'from', type: 'time', value: '00:00' },
          { key: 'to', type: 'time', value: '06:00' }
        ]
      }
    ];
  }
}
