import {
  Button,
  GhostButton,
  Input,
  Option,
  Select
} from '@kidsmanager/ui-core';
import { IUser } from '@kidsmanager/util-models';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ContextAdminUserOulet } from './context-admin-user-outlet';
import {
  minUsernameLength,
  validateUsername
} from '../helpers/validate-username';

export const AdminUserDetailProfile = () => {
  const userForm = useRef<HTMLFormElement>(null);
  const context = useOutletContext<ContextAdminUserOulet>();
  const { selectedUser, groups } = context;
  const [isDirty, setIsDirty] = useState(false);
  const [usernameHint, setUsernameHint] = useState<string>();

  useEffect(() => {
    setIsDirty(false);
  }, [selectedUser]);

  const activeUsernames = useMemo(
    () => context.activeUsers.map((u) => u.username || ''),
    [context.activeUsers]
  );
  const lockedUsernames = useMemo(
    () => context.lockedUsers.map((u) => u.username || ''),
    [context.lockedUsers]
  );
  const managers = useMemo(
    () =>
      context.activeUsers
        .filter((u) => u.roles?.some((r) => r === 'leader' || r === 'manager'))
        .filter((u) => u.userId !== selectedUser.userId)
        .map((u) => ({
          id: u.userId,
          name: `${u.lastName}, ${u.firstName}`
        })) || [],
    [context.activeUsers, selectedUser.userId]
  );

  const getUserFromForm = (formEvent: HTMLFormElement): IUser | undefined => {
    const data = new FormData(formEvent);
    return {
      userId: selectedUser.userId,
      username: data.get('username')?.toString(),
      lastName: data.get('lastName')?.toString(),
      firstName: data.get('firstName')?.toString(),
      phone: data.get('phone')?.toString(),
      email: data.get('email')?.toString(),
      managerId: data.get('managerId')?.toString(),
      primaryGroupId: data.get('primaryGroupId')?.toString()
    } as IUser;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const user = getUserFromForm(e.currentTarget);
    if (user) {
      context.onChange(user);
    }
  };

  const handleOnChange = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formUser = getUserFromForm(e.currentTarget);
    if (context.newUser) {
      const warning = validateUsername(
        formUser?.username,
        activeUsernames,
        lockedUsernames
      );
      setUsernameHint(warning);
      setIsDirty(
        !warning && (formUser?.username?.length || 0) >= minUsernameLength
      );
    } else {
      setIsDirty(
        selectedUser.firstName !== formUser?.firstName ||
          selectedUser.lastName !== formUser?.lastName ||
          selectedUser.email !== formUser?.email ||
          selectedUser.phone !== formUser?.phone ||
          selectedUser.managerId !== formUser?.managerId ||
          selectedUser.primaryGroupId !== formUser?.primaryGroupId
      );
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLFormElement>) => {
    if (context.newUser) {
      const formUser = getUserFromForm(e.currentTarget);
      const warning = validateUsername(
        formUser?.username,
        activeUsernames,
        lockedUsernames,
        minUsernameLength
      );
      setUsernameHint(warning);
      setIsDirty(!warning);
    }
  };

  return (
    <form
      ref={userForm}
      autoComplete="off"
      key={selectedUser.userId}
      className="flex flex-col gap-3"
      onBlur={handleBlur.bind(this)}
      onSubmit={handleSubmit.bind(this)}
      onChange={handleOnChange.bind(this)}
      onReset={setIsDirty.bind(this, false)}
    >
      {context.newUser && (
        <div className="relative">
          <Input
            name="username"
            label="Benutzername (Erforderlich)"
            placeholder="z.B. a.muster"
            spellCheck={false}
            mask="username"
            hint={usernameHint}
          />
        </div>
      )}
      <Input
        name="firstName"
        label="Vorname"
        defaultValue={selectedUser.firstName}
      />
      <Input
        name="lastName"
        label="Nachname"
        defaultValue={selectedUser.lastName}
      />
      <Input
        name="phone"
        label="Telefonnummer"
        defaultValue={selectedUser.phone}
        mask="phone"
      />
      <Input
        name="email"
        label="Email"
        defaultValue={selectedUser.email}
        mask="email"
      />
      <Select
        name="managerId"
        label="Vorgesetzte&#47;r"
        defaultValue={selectedUser.managerId}
      >
        <Option value="">-- Kein Vorgesetzter --</Option>
        {managers?.map((m) => (
          <Option key={m.id} value={m.id}>
            {m.name}
          </Option>
        ))}
      </Select>
      <Select
        name="primaryGroupId"
        label="Primärgruppe"
        defaultValue={selectedUser.primaryGroupId}
      >
        <Option key="" value="">
          -- Keine Gruppe --
        </Option>
        {groups.map((g) => (
          <Option key={g.groupId} value={g.groupId}>
            {g.name}
          </Option>
        ))}
      </Select>
      <div className="my-1 flex gap-2">
        <GhostButton type="reset">Abbrechen</GhostButton>
        <Button type="submit" disabled={!isDirty}>
          {context.newUser ? 'Konto Erstellen' : 'Speichern'}
        </Button>
      </div>
    </form>
  );
};
