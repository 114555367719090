import { RuleBase, RuleSpec, RulesEngineResult } from '../rule-base';

export class RuleBlockedDays extends RuleBase {
  test(): RulesEngineResult {
    return { status: 'ok' };
  }
  specs(): RuleSpec[] {
    return [
      {
        id: 'public-hols',
        description:
          'Arbeiten an einem Feiertag ist nur mit Freigabe der Leitung erlaubt',
        defaults: []
      },
      {
        id: 'sundays',
        description:
          'Arbeiten an einem Sonntag ist nur mit Freigabe der Leitung erlaubt',
        defaults: []
      }
    ];
  }
}
