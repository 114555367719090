import {
  EntityUser,
  EntityUserAllowance,
  Role,
  tableKeys
} from '@kidsmanager/util-storage';

// TODO Richard: Review usage
export interface IUserGroup {
  name: string;
  users: IUser[];
}

export interface IUser {
  userId: string;
  username?: string;
  lastName?: string;
  firstName?: string;
  phone?: string;
  email?: string;
  managerId?: string;
  primaryGroupId?: string;
  groupIds?: string[];
  roles?: IRole[];
  employeeId?: string;
  originalStartDate?: string;
  costCenter?: string;
  dutyRosters?: string;
  hoursPerWeek?: number;
  holidayAllowance?: number;
}

export interface IUserAllowance {
  value: number;
  from: string;
  to?: string;
}

export type IRole =
  | 'admin'
  | 'manager'
  | 'controller'
  | 'leader'
  | 'user'
  | 'guest';

export const ROLE_LABELS: Map<IRole, string> = new Map([
  ['admin', 'Administrator'],
  ['manager', 'ManagerIn'],
  ['controller', 'Buchhaltung'],
  ['leader', 'TeamleiterIn'],
  ['user', 'BetreuerIn'],
  ['guest', 'Gast']
]);

export const mapUserFromEntity = (
  user: EntityUser,
  userId: string,
  roles: Role[]
): IUser => {
  return {
    userId: userId,
    username: user.Username,
    lastName: user.LastName || '',
    firstName: user.FirstName || '',
    email: user.Email || '',
    phone: user.Telephone || '',
    roles: roles,
    primaryGroupId: user.PrimaryGroupId || '',
    groupIds: user.GroupList?.split(';') || [],
    managerId: user.ManagerId || '',
    employeeId: user.EmployeeId,
    originalStartDate: user.OriginalStartDate,
    costCenter: user.CostCenter,
    dutyRosters: user.DutyRosters,
    hoursPerWeek: user.HoursPerWeek,
    holidayAllowance: user.HolidayAllowance
  };
};

export const mapUserToEntity = (tenant: string, user: IUser): EntityUser => {
  return {
    partitionKey: tableKeys.user.pk(tenant),
    rowKey: tableKeys.user.rk.byUserId(user.userId),
    Username: user.username,
    FirstName: user.firstName,
    LastName: user.lastName,
    Email: user.email,
    Telephone: user.phone,
    PrimaryGroupId: user.primaryGroupId,
    GroupList: user.groupIds?.join(';'),
    ManagerId: user.managerId,
    EmployeeId: user.employeeId,
    OriginalStartDate: user.originalStartDate,
    CostCenter: user.costCenter,
    DutyRosters: user.dutyRosters,
    HoursPerWeek: user.hoursPerWeek,
    HolidayAllowance: user.holidayAllowance
  } as EntityUser;
};

export const mapUserAllowanceToEntity = (
  tenant: string,
  userId: string,
  type: 'hours' | 'holiday',
  allowance: IUserAllowance
): EntityUserAllowance => {
  return {
    partitionKey: tableKeys.user.allowances.pk(tenant),
    rowKey: tableKeys.user.allowances.rk.exact(userId, type, allowance.from),
    Type: type,
    From: allowance.from,
    To: allowance.to,
    Value: allowance.value
  } as EntityUserAllowance;
};
