import { Tabs } from '@kidsmanager/ui-core';
import { hasModule, IUser, Modules } from '@kidsmanager/util-models';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

export interface UserDetailProps {
  selectedUser: IUser;
  modules: number;
  children?: React.ReactNode;
}

const formatUsername = (lastName?: string, firstName?: string): string => {
  if (!firstName) {
    return lastName || '';
  }
  return `${lastName}, ${firstName}`;
};

export const UserDetails = (props: UserDetailProps) => {
  const tabs = useMemo(() => {
    const t = [
      { path: 'profile', title: 'Profil' },
      { path: 'permissions', title: 'Berechtigungen' }
    ];
    if (hasModule(props.modules, Modules.Timesheets)) {
      t.push({ path: 'shifts', title: 'Dienste' });
    }
    return t;
  }, [props.modules]);

  const handleSelectUsername = (e: React.MouseEvent<HTMLSpanElement>) => {
    const range = document.createRange();
    const selection = window.getSelection();
    selection?.removeAllRanges();
    range.selectNodeContents(e.currentTarget);
    selection?.addRange(range);
  };

  return (
    <div className="relative">
      <h1 className="mb-4 text-2xl font-semibold">
        {props.selectedUser.userId === 'new' ? (
          'Neuer BenuzerIn'
        ) : (
          <>
            {formatUsername(
              props.selectedUser.lastName,
              props.selectedUser.firstName
            )}
            <span className="ml-2 h-10 text-base font-normal text-black/60">
              [
              <span
                className="px-0.5"
                onClick={handleSelectUsername.bind(this)}
              >
                {props.selectedUser.username}
              </span>
              ]
            </span>
          </>
        )}
      </h1>
      <Link
        to="/admin/users"
        className="absolute right-0 top-0 -mt-4 text-4xl text-gray-600 hover:text-black"
        aria-label="Close"
      >
        &times;
      </Link>
      <Tabs basePath={`/admin/users/${props.selectedUser.userId}`} tabs={tabs}>
        {props.children}
      </Tabs>
    </div>
  );
};
