const urlAlphabet =
  'useandom26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict';

export const quickid = (
  prefix = '',
  strength: 'low' | 'medium' | 'high' = 'medium'
) => {
  let t = '';
  const l = strength === 'low' ? 10 : strength === 'medium' ? 16 : 25;
  const r = window.crypto.getRandomValues(new Uint8Array(l));
  for (let n = 0; n < l; n++) t += urlAlphabet[61 & r[n]];
  return `${prefix}${t}`;
};
