import { RulesEngineResult, RuleBase, RuleSpec } from '../rule-base';

export class RuleNightShiftRequired extends RuleBase {
  test(from: Date, until: Date): RulesEngineResult {
    console.log('RuleNightShiftRequired.test', from, until);
    return { status: 'ok' };
  }
  specs(): RuleSpec[] {
    return [
      {
        id: 'night-shift-required',
        description:
          'Dienste, die länger als {length} Stunden dauern, erfordern einen “Nachtdienst” von {from} bis {to} Uhr',
        defaults: [
          { key: 'length', type: 'number', value: '18' },
          { key: 'from', type: 'time', value: '22:00' },
          { key: 'to', type: 'time', value: '06:00' }
        ]
      }
    ];
  }
}
