import {
  DayData,
  RulesEngineResult,
  WorkHistory,
  RuleBase,
  Assignment,
  ShiftScope,
  BlockStatus
} from './rule-base';
import * as all from './rules';

export class WorkRulesEngine {
  history: WorkHistory = { previous: [], current: [], next: [] };

  rules: RuleBase[] = [
    new all.RuleNightShiftRequired(),
    new all.RuleOnCallRequired(),
    new all.RuleMinHours(),
    new all.RuleMaxHours(),
    new all.RuleNoOverlap(),
    new all.RuleMinRestInWeek(),
    new all.RuleMaxWeekendsInYear(),
    new all.RulesRestAfterNightShift(),
    new all.RuleAccountingPeriod(),
    new all.RuleAverageHoursOverYear(),
    new all.RuleRestPeriods(),
    new all.RuleWeekendNeeds(),
    new all.RuleBlockedDays(),
    new all.RuleBlockedHours()
  ];

  setHistory(previous: DayData[], current: DayData[], next: DayData[]): void {
    this.history = { previous, current, next };
  }

  test(from: Date, until: Date): RulesEngineResult {
    let warning: RulesEngineResult | undefined = undefined;
    for (const rule of this.rules) {
      const result = rule.test(from, until, this.history);
      if (result.status === 'fail') {
        return result;
      }
      if (!warning && result.status === 'warn') {
        warning = result;
      }
    }
    return warning ? warning : { status: 'ok' };
  }

  check(
    test: ShiftScope,
    days: Date[],
    plan: Assignment[][],
    blocked: BlockStatus[]
  ): BlockStatus[] {
    for (const rule of this.rules) {
      rule.check(test, days, plan, blocked);
    }
    return blocked;
  }
}
