import { InlineInput, SettingsTable } from '@kidsmanager/ui-core';
import { datesOverlap, prependToList } from '@kidsmanager/util-common';
import { IUserAllowance } from '@kidsmanager/util-models';
import { useEffect, useState } from 'react';

export interface UserAllowanceEditorProps {
  title: string;
  label: string;
  default: number;
  values: IUserAllowance[];
  onChange?: (values: IUserAllowance[], overlaps: boolean) => void;
}

export const UserAllowanceEditor = (props: UserAllowanceEditorProps) => {
  const [values, setValues] = useState<IUserAllowance[]>([]);
  const [overlaps, setOverlaps] = useState<boolean>(false);

  useEffect(() => {
    setValues(props.values);
  }, [props.values]);

  const notifyChange = (update: IUserAllowance[]) => {
    const hasOverlap = datesOverlap(update);
    setOverlaps(hasOverlap);
    props.onChange?.(update, hasOverlap);
  };

  const handleAdd = () => {
    const update = prependToList<IUserAllowance[]>(values, new Date());
    update[0].value = values.length > 0 ? values[0].value : props.default;
    setValues(update);
    notifyChange(update);
  };

  const handleChangeValue = (value: IUserAllowance, newValue: string) => {
    value.value = Number(newValue);
    setValues((prev) => {
      const update = [...prev];
      notifyChange(update);
      return update;
    });
  };

  const handleChangeFrom = (value: IUserAllowance, from: string) => {
    value.from = from;
    setValues((prev) => {
      const update = [...prev];
      notifyChange(update);
      return update;
    });
  };

  const handleChangeTo = (value: IUserAllowance, to: string) => {
    value.to = to;
    setValues((prev) => {
      const update = [...prev];
      notifyChange(update);
      return update;
    });
  };

  const handleRemove = (value: IUserAllowance) => {
    setValues((prev) => {
      const update = prev.filter((x) => x !== value);
      notifyChange(update);
      return update;
    });
  };

  return (
    <div className="-mb-2">
      <SettingsTable
        label={props.title}
        columns={[
          { label: props.label, minWidth: '80px' },
          { label: 'Von', width: '120px' },
          { label: 'Bis', width: '120px' },
          { label: '', width: '30px' }
        ]}
        onAdd={handleAdd.bind(this)}
      >
        {values.map((value) => (
          <tr key={`${value.from}`} className="group">
            <td className="w-8">
              <InlineInput
                mask="number"
                value={value.value.toString()}
                onChange={(val) => handleChangeValue(value, val)}
              />
            </td>
            <td>
              <span className="inline-block w-24">
                <InlineInput
                  mask="date"
                  value={value.from}
                  onChange={(from) => handleChangeFrom(value, from)}
                />
              </span>
            </td>
            <td>
              <span className="inline-block w-24">
                <InlineInput
                  mask="date"
                  value={value.to || ''}
                  onChange={(to) => handleChangeTo(value, to)}
                />
              </span>
            </td>
            <td className="flex items-center">
              <span
                className="material-icons cursor-pointer opacity-0 group-hover:opacity-100"
                onClick={handleRemove.bind(this, value)}
              >
                close
              </span>
            </td>
          </tr>
        ))}
        <tr>
          <td className="w-8"></td>
          <td className="w-24"></td>
          <td className="w-24"></td>
          <td></td>
        </tr>
      </SettingsTable>
      <p className="text-error h-4 text-right text-xs">
        {overlaps && <span>Datumsbereiche überschneiden sich</span>}
      </p>
    </div>
  );
};
