import { Button, ChipGroup, GhostButton } from '@kidsmanager/ui-core';
import { arrayEqual } from '@kidsmanager/util-common';
import { IRole, IUser, ROLE_LABELS } from '@kidsmanager/util-models';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ContextAdminUserOulet } from './context-admin-user-outlet';

export const AdminUserDetailPermissions = () => {
  const context = useOutletContext<ContextAdminUserOulet>();
  const { selectedUser, groups } = context;
  const allRoles: IRole[] = Array.from(ROLE_LABELS.keys());
  const [roles, setRoles] = useState<IRole[] | undefined>([]);
  const [groupIds, setGroupIds] = useState<string[] | undefined>([]);

  // TODO add cached roles and group id/names - where to load the from?

  useEffect(() => {
    setRoles(selectedUser.roles);
    setGroupIds(selectedUser.groupIds);
  }, [selectedUser]);

  const handleSubmit = () => {
    context.onChange({
      userId: selectedUser.userId,
      roles: roles,
      groupIds: groupIds
    } as IUser);
  };

  return (
    <div className="flex max-w-2xl flex-col gap-4">
      <ChipGroup
        chips={
          allRoles?.map((r) => ({ id: r, label: ROLE_LABELS.get(r) || 'r' })) ||
          []
        }
        selectedIds={roles?.map((s) => s.toString()) || []}
        color="#F1EFDE"
        label="Rollen"
        onChange={(ids) => setRoles(ids?.map((s) => s as IRole) || [])}
      />
      <ChipGroup
        chips={groups?.map((g) => ({ id: g.groupId, label: g.name })) || []}
        selectedIds={groupIds || []}
        color="#F1EFDE"
        label="Gruppen"
        onChange={(ids) => setGroupIds(ids)}
      />
      <div className="my-1 flex gap-2">
        <GhostButton type="reset">Abbrechen</GhostButton>
        <Button
          type="submit"
          disabled={
            !(selectedUser.userId !== 'new' || !!selectedUser?.username) ||
            (arrayEqual(selectedUser.roles, roles) &&
              arrayEqual(selectedUser.groupIds, groupIds))
          }
          onClick={handleSubmit.bind(this)}
        >
          Speichern
        </Button>
      </div>
    </div>
  );
};
