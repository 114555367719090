import { RuleBase, RuleSpec, RulesEngineResult } from '../rule-base';

export class RuleBlockedHours extends RuleBase {
  test(): RulesEngineResult {
    return { status: 'ok' };
  }
  specs(): RuleSpec[] {
    return [
      {
        id: 'before-x',
        description:
          'Arbeiten vor {before} ist nur mit Freigabe der Leitung erlaubt',
        defaults: [{ key: 'before', type: 'time', value: '06:00' }]
      },
      {
        id: 'after-y',
        description:
          'Arbeiten nach {after} ist nur mit Freigabe der Leitung erlaub',
        defaults: [{ key: 'after', type: 'time', value: '22:00' }]
      }
    ];
  }
}
