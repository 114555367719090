import { ClientBackendContext } from '@kidsmanager/ui-api';
import { IGroup, IRosterTemplate, IUser } from '@kidsmanager/util-models';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserDetails } from './components/user-details';
import { UserCard } from './components/user-card';
import { LinkButton } from '@kidsmanager/ui-core';
import { ContextAdminUserOulet } from './admin-user-details/context-admin-user-outlet';
import { MasterDetail } from '../components/master-detail';
import { UserSearch } from './components/user-search';
import { UserSearchSummary } from './components/user-search-summary';

const userSort = (a: IUser, b: IUser) =>
  (a.lastName || '').localeCompare(b.lastName || '');

export const AdminUsers = () => {
  const client = useContext(ClientBackendContext);
  const [, setGoogleUserEmails] = useState<string[]>([]); // needed for hinzufuegen

  const [modules, setModules] = useState(0);
  const [activeUsers, setActiveUsers] = useState<IUser[]>([]);
  const [lockedUsers, setLockedUsers] = useState<IUser[]>([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [filtered, setFiltered] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser>();

  const [groups, setGroups] = useState<IGroup[]>([]);
  const [templates, setTemplates] = useState<IRosterTemplate[]>([]);

  const params = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (client.auth.idp()?.toLocaleLowerCase() === 'google') {
      client.admin.users.google().then((users) => {
        if (!users) {
          return;
        }
        setGoogleUserEmails(users);
      });
    }
    client.admin.users.active().then((users) => {
      if (users) {
        users.sort(userSort);
        setActiveUsers(users);
      }
      client.admin.users.locked().then(setLockedUsers);
    });
    client.admin.roster.templates.find().then(setTemplates);
    client.admin.groups.all().then(setGroups);
    setModules(client.auth.modules());
  }, [client]);

  useEffect(() => {
    setFiltered(activeUsers);
    setTotalUsers(activeUsers.length);
  }, [groups, activeUsers]);

  useEffect(() => {
    if (params.id) {
      if (params.id === 'new') {
        setSelectedUser({ userId: 'new' } as IUser);
      } else {
        const found = activeUsers.find((u) => u.userId === params.id);
        if (found) {
          setSelectedUser(found);
        } else {
          setSelectedUser(lockedUsers.find((u) => u.userId === params.id));
        }
      }
    } else {
      setSelectedUser(undefined);
    }
  }, [activeUsers, lockedUsers, params]);

  const handleSearchSubmit = () => {
    if (filtered.length > 0) {
      navigate(`/admin/users/${filtered[0].userId}/profile`);
    }
  };

  const handleSearch = (users: IUser[], locked: boolean) => {
    setFiltered(users);
    setTotalUsers(locked ? lockedUsers.length : activeUsers.length);
  };

  const handleUserChange = (user: IUser) => {
    if (user.userId === 'new') {
      client.admin.users.create(user).then((updatedUser) => {
        if (updatedUser) {
          setActiveUsers((prev) => {
            const updated = [...prev, updatedUser];
            updated.sort(userSort);
            return updated;
          });
          navigate(`/admin/users/${updatedUser.userId}/profile`);
        }
      });
    } else {
      client.admin.users
        .update(user)
        .then((updatedUser) =>
          setActiveUsers((prev) =>
            prev?.map((u) =>
              u.userId === updatedUser.userId ? updatedUser : u
            )
          )
        );
    }
  };

  return (
    <MasterDetail
      widths={{ detail: 550 }}
      master={
        <div className="p-4">
          <div className="sticky top-0 mb-2 bg-white/90 pb-2 pt-5 backdrop-blur-sm">
            <UserSearch
              groups={groups}
              templates={templates}
              activeUsers={activeUsers}
              lockedUsers={lockedUsers}
              onSearch={handleSearch.bind(this)}
              onSubmit={handleSearchSubmit.bind(this)}
            />
          </div>
          <div className="flex justify-between text-sm">
            <UserSearchSummary matched={filtered.length} total={totalUsers} />
            <LinkButton onClick={() => navigate('/admin/users/new/profile')}>
              + hinzuf&uuml;gen
            </LinkButton>
          </div>
          <div className="mt-2 flex flex-wrap gap-2">
            {filtered.map((user, index) => (
              <UserCard
                key={user.userId}
                name={user?.lastName || ''}
                firstName={user?.firstName || ''}
                userId={user.userId || ''}
                selected={user.userId === selectedUser?.userId}
                path={
                  params.id
                    ? location.pathname.slice(
                        location.pathname.lastIndexOf('/') + 1
                      )
                    : 'profile'
                }
              />
            ))}
          </div>
        </div>
      }
      detail={
        selectedUser && (
          <UserDetails selectedUser={selectedUser} modules={modules}>
            <Outlet
              context={
                {
                  newUser: selectedUser.userId === 'new',
                  selectedUser,
                  groups,
                  templates,
                  activeUsers,
                  lockedUsers,
                  onChange: handleUserChange
                } as ContextAdminUserOulet
              }
            />
          </UserDetails>
        )
      }
    />
  );
};
