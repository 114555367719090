export const minUsernameLength = 5;

export const validateUsername = (
  username: string | undefined,
  active: string[],
  locked: string[],
  minLength = 0
): string | undefined => {
  username = username?.trim() || '';
  if (minLength > 0 && username.length < minLength) {
    return `Benutzername muss mindestens ${minLength} Zeichen lang sein`;
  }
  if (active.includes(username)) {
    return 'Aktiver Benutzer hat diesen Benutzernamen';
  }
  if (locked.includes(username)) {
    return 'Gesperrter Benutzer hat diesen Benutzernamen';
  }
  return undefined;
};
