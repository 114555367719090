import {
  RulesEngineResult,
  TicksPerHour,
  RuleBase,
  RuleSpec
} from '../rule-base';

export class RuleMaxHours extends RuleBase {
  test(from: Date, until: Date): RulesEngineResult {
    const hours = (until.getTime() - from.getTime()) / TicksPerHour;
    if (hours > 30) {
      return {
        status: 'fail',
        message: 'Maximal 30 Stunden pro Eintrag sind erlaubt'
      };
    } else if (hours > 25) {
      return {
        status: 'warn',
        message: 'Über 25 Stunden erfordert eine zusätzliche Genehmigung'
      };
    }
    return { status: 'ok' };
  }
  specs(): RuleSpec[] {
    return [
      {
        id: 'max-hours-day',
        description:
          'Dienste, die über {length} Stunden dauern, erfordern die Freigabe der Leitung',
        defaults: [
          {
            key: 'length',
            type: 'number',
            value: '25'
          }
        ]
      },
      {
        id: 'max-hours-week',
        description:
          'Arbeiten von über {length} Stunden pro Woche erfordert die Genehmigung der Leitung',
        defaults: [
          {
            key: 'length',
            type: 'number',
            value: '60'
          }
        ]
      }
    ];
  }
}
