import { Search } from '@kidsmanager/ui-core';
import { UserAdvancedSearch } from './user-advanced-search';
import { IGroup, IRosterTemplate, IUser } from '@kidsmanager/util-models';
import { FilterDefinition, FilterLogic } from '../helpers/filter-logic';
import { useMemo, useState } from 'react';

export interface UserSearchProps {
  groups: IGroup[];
  templates: IRosterTemplate[];
  activeUsers: IUser[];
  lockedUsers: IUser[];
  onSearch?: (users: IUser[], locked: boolean) => void;
  onSubmit?: () => void;
}

export const UserSearch = (props: UserSearchProps) => {
  const [searchExpression, setSearchExpression] = useState('');
  const [filterDef, setFilterDef] = useState<FilterDefinition>();
  const filterLogic = useMemo(() => new FilterLogic(), []);

  const handleSimpleSearch = (expression: string) => {
    const def = filterLogic.fromExpression(expression);
    props.onSearch?.(
      filterLogic.apply(
        props.groups,
        props.templates,
        props.activeUsers,
        props.lockedUsers,
        def
      ),
      !!def.locked
    );
    setSearchExpression(expression);
    setFilterDef(def);
  };

  const handleAdvancedSearch = (value: FilterDefinition) => {
    props.onSearch?.(
      filterLogic.apply(
        props.groups,
        props.templates,
        props.activeUsers,
        props.lockedUsers,
        value
      ),
      !!value.locked
    );
    setSearchExpression(filterLogic.toExpression(value));
    setFilterDef(value);
  };

  const handleSearchClear = () => {
    setTimeout(() => props.onSearch?.(props.activeUsers, false));
  };

  return (
    <Search
      id="kidsmgr-user"
      placeholder="Filter nach Name..."
      value={searchExpression}
      onSearch={handleSimpleSearch.bind(this)}
      onClear={handleSearchClear.bind(this)}
      onSubmit={props.onSubmit?.bind(this)}
    >
      <UserAdvancedSearch
        groups={props.groups}
        templates={props.templates}
        activeUsers={props.activeUsers}
        value={filterDef}
        onSearch={handleAdvancedSearch.bind(this)}
      />
    </Search>
  );
};
