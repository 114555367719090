import {
  IUser,
  IGroup,
  ROLE_LABELS,
  IRosterTemplate
} from '@kidsmanager/util-models';

export interface FilterDefinition {
  text?: string;
  group?: string;
  role?: string;
  template?: string;
  manager?: string;
  locked?: boolean;
}

const roleLookup = Array.from(ROLE_LABELS.keys())
  .sort((a, b) => (a > b ? -1 : 1))
  .reduce(
    (prev, id) => {
      prev.push({ id, label: ROLE_LABELS.get(id)?.toLowerCase() || '' });
      return prev;
    },
    [] as { id: string; label: string }[]
  );

export class FilterLogic {
  groupRegex = /g(?:ruppe)?=([^\s]+)/i;
  roleRegex = /r(?:olle)?=([^\s]+)/i;
  templateRegex = /d(?:ienstvorlage)?=([^\s]+)/i;
  managerRegex = /v(?:orgesetzeter)?=([^\s]+)/i;
  statusRegex = /s(?:tatus)?=([^\s]+)/i;

  toExpression(def: FilterDefinition): string {
    let result = '';
    if (def.group) {
      result += `g=${def.group} `;
    }
    if (def.role) {
      result += `r=${def.role} `;
    }
    if (def.template) {
      result += `d=${def.template} `;
    }
    if (def.manager) {
      result += `v=${def.manager} `;
    }
    if (def.locked) {
      result += 's=gesperrt ';
    }
    if (def.text) {
      result += def.text;
    }
    return result;
  }

  fromExpression(value: string): FilterDefinition {
    const definition = {} as FilterDefinition;

    value = value.toLowerCase();
    const group = this.groupRegex.exec(value);
    if (group) {
      value = value.replace(group[0], '');
      definition.group = group[1].trim();
    }
    const role = this.roleRegex.exec(value);
    if (role) {
      value = value.replace(role[0], '');
      definition.role = role[1].trim();
    }
    const template = this.templateRegex.exec(value);
    if (template) {
      value = value.replace(template[0], '');
      definition.template = template[1].trim();
    }
    const manager = this.managerRegex.exec(value);
    if (manager) {
      value = value.replace(manager[0], '');
      definition.manager = manager[1].trim();
    }
    const status = this.statusRegex.exec(value);
    if (status) {
      value = value.replace(status[0], '');
      definition.locked = status[1].trim() === 'gesperrt';
    }

    const text = value.trim();
    if (text.length > 0) {
      definition.text = text;
    }
    return definition;
  }

  apply(
    groups: IGroup[],
    templates: IRosterTemplate[],
    active: IUser[],
    locked: IUser[],
    def: FilterDefinition
  ): IUser[] {
    let filtered: IUser[] = def.locked ? [...locked] : [...active];
    if (def.group) {
      const pattern = def.group.toLowerCase();
      const test = groups.filter((g) => g.name.toLowerCase().includes(pattern));
      filtered = filtered.filter((user) =>
        test.some((g) => user.groupIds?.includes(g.groupId))
      );
    }

    if (def.role) {
      const pattern = def.role.toLowerCase();
      const test = roleLookup.find((r) => r.label.startsWith(pattern))?.id;
      filtered = filtered.filter((user) => user.roles?.some((r) => r === test));
    }

    if (def.template) {
      const pattern = def.template.toLowerCase();
      if (pattern === 'ohne') {
        filtered = filtered.filter((user) => !user.dutyRosters);
      } else {
        const test = templates.filter((t) =>
          t.name.toLowerCase().includes(pattern)
        );
        filtered = filtered.filter((user) =>
          test.some((t) => user.dutyRosters?.includes(t.id))
        );
      }
    }

    if (def.manager) {
      const pattern = def.manager.toLowerCase();
      if (pattern === 'ohne') {
        filtered = filtered.filter((user) => !user.managerId);
      } else {
        const test = active.filter(
          (u) =>
            u.roles?.some((r) => r === 'leader' || r === 'manager') &&
            (u.lastName?.toLowerCase().includes(pattern) ||
              u.firstName?.toLowerCase().includes(pattern))
        );
        filtered = filtered.filter((user) =>
          test.some((u) => user.managerId === u.userId)
        );
      }
    }

    if (def.text && def.text.length > 1) {
      // start after the first character to give chance to specify advanced search g= or r=
      if (!def.text.includes('=')) {
        const pattern = def.text.toLowerCase();
        filtered = filtered.filter((user) => {
          return (
            user.firstName?.toLowerCase().includes(pattern) ||
            user.lastName?.toLowerCase().includes(pattern)
          );
        });
      }
    }
    return filtered;
  }
}
