import {
  Button,
  GhostButton,
  Input,
  Option,
  Select
} from '@kidsmanager/ui-core';
import { useContext, useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ContextAdminUserOulet } from './context-admin-user-outlet';
import { UserAllowanceEditor } from './user-allowance-editor';
import { IUserAllowance } from '@kidsmanager/util-models';
import { ClientBackendContext } from '@kidsmanager/ui-api';

export const AdminUserDetailShifts = () => {
  const ref = useRef<HTMLFormElement>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [workInvalid, setWorkInvalid] = useState(false);
  const [holsInvalid, setHolsInvalid] = useState(false);
  const [hours, setHours] = useState<IUserAllowance[]>([]);
  const [holidays, setHolidays] = useState<IUserAllowance[]>([]);

  const client = useContext(ClientBackendContext);
  const context = useOutletContext<ContextAdminUserOulet>();
  const { selectedUser: user } = context;

  const handleCancel = () => {
    setIsDirty(false);
    ref.current?.reset();
    client.admin.users.allowances(user.userId).then((allowances) => {
      setHours(structuredClone(allowances.hours) || []);
      setHolidays(structuredClone(allowances.holiday) || []);
    });
  };

  useEffect(() => {
    client.admin.users.allowances(user.userId).then((allowances) => {
      setHours(structuredClone(allowances.hours) || []);
      setHolidays(structuredClone(allowances.holiday) || []);
    });
  }, [client, user.userId]);

  const handleSave = async () => {
    if (!ref.current) {
      return;
    }
    const data = new FormData(ref.current);
    const update = { ...user };
    update.employeeId = data.get('employeeId')?.toString();
    update.originalStartDate = data.get('originalStartDate')?.toString();
    update.costCenter = data.get('costCenter')?.toString();
    update.dutyRosters = data.get('dutyRosters')?.toString();

    update.hoursPerWeek = hours[0]?.value ?? 0;
    update.holidayAllowance = holidays[0]?.value ?? 0;

    await Promise.all([
      client.admin.users.updateAllowances(user.userId, 'hours', hours),
      client.admin.users.updateAllowances(user.userId, 'holiday', holidays)
    ]);

    context.onChange(update);
    setIsDirty(false);
  };

  const handleHoursChange = (values: IUserAllowance[], overlaps: boolean) => {
    if (!overlaps) {
      setHours(values);
      setIsDirty(true);
    }
    setWorkInvalid(overlaps);
  };

  const handleHolidayChange = (values: IUserAllowance[], overlaps: boolean) => {
    if (!overlaps) {
      setHolidays(values);
      setIsDirty(true);
    }
    setHolsInvalid(overlaps);
  };

  return (
    <div className="flex max-w-md flex-col gap-4">
      <form
        ref={ref}
        key={`${user.userId}-${context.templates.length}`}
        autoComplete="off"
        className="flex flex-col gap-3"
        onChange={setIsDirty.bind(this, true)}
      >
        <Input
          name="employeeId"
          label="Dienstnummer"
          defaultValue={user.employeeId}
        />
        <Input
          name="originalStartDate"
          label="erstes Einstellungsdatum"
          placeholder="TT.MM.JJJJ"
          mask="date"
          defaultValue={user.originalStartDate}
        />
        <Input
          name="costCenter"
          label="Kostenstelle"
          defaultValue={user.costCenter}
        />
        <Select
          name="dutyRosters"
          label="Dienstvorlage"
          defaultValue={user.dutyRosters}
        >
          <Option value="">-- Keine Dienstvorlage --</Option>
          {context.templates?.map((template) => (
            <Option key={template.id} value={template.id}>
              {template.name}
            </Option>
          ))}
        </Select>
      </form>
      <UserAllowanceEditor
        title="Wochenstunden"
        label="Stunden"
        default={38}
        values={hours}
        onChange={handleHoursChange.bind(this)}
      />
      <UserAllowanceEditor
        title="Urlaub"
        label="Tage"
        default={25}
        values={holidays}
        onChange={handleHolidayChange.bind(this)}
      />

      <div className="my-1 flex gap-2">
        <GhostButton onClick={handleCancel.bind(this)}>Abbrechen</GhostButton>
        <Button
          disabled={!isDirty || workInvalid || holsInvalid}
          onClick={handleSave.bind(this)}
        >
          Speichern
        </Button>
      </div>
    </div>
  );
};
