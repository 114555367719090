import { RuleBase, RuleSpec, RulesEngineResult } from '../rule-base';

export class RuleRestPeriods extends RuleBase {
  test(): RulesEngineResult {
    return { status: 'ok' };
  }
  specs(): RuleSpec[] {
    return [
      {
        id: 'rest-daily',
        description:
          'Eine tägliche Ruhezeit von {hours} Stunden ist erforderlich, wenn keine Nachtdienst geleistet wird',
        defaults: [{ key: 'hours', type: 'number', value: '11' }]
      },
      {
        id: 'rest-weekly',
        description:
          'Eine wöchentliche Ruhezeit von {days} aufeinanderfolgenden Tagen ist erforderlich',
        defaults: [{ key: 'days', type: 'number', value: '2' }]
      }
    ];
  }
}
