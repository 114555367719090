import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ShiftRule } from './components/shift-rule';
import { WorkRulesEngine } from '@kidsmanager/util-rules';
import { ContextAdminRosterOutlet } from './context-admin-roster-outlet';
import { IRosterRuleValue } from '@kidsmanager/util-models';

interface RuleDefinition {
  id: string;
  description: string;
  values: IRosterRuleValue[];
  enabled: boolean;
}

export const AdminRosterRules = () => {
  const context = useOutletContext<ContextAdminRosterOutlet>();
  const { template } = context;
  const [rules, setRules] = useState<RuleDefinition[]>([]);

  useEffect(() => {
    if (!template) {
      return;
    }
    setRules(() => []);
    //push setting to the next event loop to allow cancel changes
    setTimeout(() => {
      const engine = new WorkRulesEngine();
      setRules(
        engine.rules.flatMap((rule) =>
          rule.specs().map((spec) => {
            const config = template.rules.find((x) => x.id === spec.id);
            const values = config?.values || spec.defaults;
            return {
              description: spec.description,
              id: spec.id,
              values,
              enabled: config?.enabled || false
            };
          })
        )
      );
    }, 0);
  }, [template]);

  const handleChange = (
    ruleId: string,
    values: IRosterRuleValue[],
    enabled: boolean
  ) => {
    const index = template?.rules.findIndex((x) => x.id === ruleId);
    if (index === -1) {
      template.rules.push({
        id: ruleId,
        values: [...values],
        enabled
      });
    } else {
      template.rules[index].values = [...values];
      template.rules[index].enabled = enabled;
    }
    context.onChange?.();
  };

  return (
    rules && (
      <div className="pl-1">
        {rules.map((rule) => (
          <ShiftRule
            key={`${template?.id}-${rule.id}`}
            description={rules.find((x) => x.id === rule.id)?.description || ''}
            values={rule.values}
            enabled={rule.enabled}
            onChange={(values, enabled) =>
              handleChange(rule.id, values, enabled)
            }
          />
        ))}
      </div>
    )
  );
};
