import { useMemo } from 'react';
import { determineTextColor } from '../helper';

export interface ChipProps {
  id: string;
  label: string;
  color: string;
  onClick?: (e: React.MouseEvent) => void;
}

export const Chip = (props: ChipProps) => {
  const textColor = useMemo(
    () => determineTextColor(props.color),
    [props.color]
  );
  const color = props.color;

  return (
    <div
      tabIndex={0}
      className={`outline-focus flex max-w-fit items-center justify-center rounded-full px-1.5 py-0.5 text-center outline-offset-4`}
      style={{
        backgroundColor: color,
        color: textColor,
        userSelect: 'none'
      }}
    >
      <span className="px-2 opacity-100">{props.label}</span>
      <span
        className="material-icons flex cursor-pointer items-center justify-center rounded-full bg-neutral-500 !text-xs opacity-70 hover:bg-neutral-600"
        style={{ width: '1rem', color: color }}
        onClick={props.onClick}
      >
        close
      </span>
    </div>
  );
};
